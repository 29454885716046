import {useState} from 'react';
import Image from 'next/image';
import {shallowEqual, useSelector} from 'react-redux';
import {Typography, Link, Skeleton} from '@mui/material';
import {Section, Grid, Box} from '@jobseeker/lotus/lib/foundations';
import useLoadAds from '@jobseeker/common-core/lib/hooks/useLoadAds';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

import type {EnhanceSkillType} from '../types';
import type {AppState, AdsElementType} from '@jobseeker/common-core/lib/types';

const EnhanceSkill = (props: EnhanceSkillType) => {
  const [skillInfo, setSkillInfo] = useState(Array<AdsElementType>);
  const {eventTag, heading, theme, slotId, isAboveMobile, isLargeXl} =
    props || {};

  const skills = useSelector(
    (state: AppState) => state.ads.enhanceSkills,
    shallowEqual
  );

  useLoadAds(slotId, setSkillInfo, {}, false, !skills[0]?.title);

  const loadedSkills = skills[0]?.title ? skills : skillInfo;

  return (
    <Section>
      <Typography
        component={'h2'}
        sx={{
          textAlign: 'center',
          fontSize: {
            xxs: theme.typography.pxToRem(20),
            md: theme.typography.pxToRem(26),
          },
          fontWeight: {xxs: 600, md: 400},
        }}
      >
        {heading ? (
          heading
        ) : (
          <Skeleton
            key={0}
            animation="wave"
            width={150}
            height={24}
            sx={{
              textAlign: 'center',
            }}
          />
        )}
      </Typography>

      <Grid
        container
        width="100%"
        sx={{
          px: {xxs: 3, sm: 3, md: 5, lg: 8},
          marginTop: {xxs: theme.spacing(3.125), md: theme.spacing(5.625)},
          margin: 0,
          textAlign: 'center',
        }}
      >
        {loadedSkills[0]?.imageUrl ? (
          loadedSkills.map((skill, index) => (
            <Grid
              item
              xxs={12}
              sm={2.4}
              key={`home-enhanceskills-${index}`}
              sx={{
                borderTop: `${theme.spacing(0.125)} solid ${
                  theme.palette.gray.light
                }`,
                borderBottom: {
                  xxs: '0',
                  sm: `${theme.spacing(0.125)} solid ${
                    theme.palette.gray.light
                  }`,
                },
                borderRight: isAboveMobile
                  ? '0'
                  : `${theme.spacing(0.125)} solid ${theme.palette.gray.light}`,
                borderLeft: `${theme.spacing(0.125)} solid ${
                  theme.palette.gray.light
                }`,
                float: 'left',
                '&:nth-of-type(5n)': {
                  borderRight: `${theme.spacing(0.125)} solid ${
                    theme.palette.gray.light
                  }`,
                  borderBottom: {
                    xxs: `${theme.spacing(0.125)} solid ${
                      theme.palette.gray.light
                    }`,
                    sm: `${theme.spacing(0.125)} solid ${
                      theme.palette.gray.light
                    }`,
                  },
                },
                verticalAlign: 'top',
                boxSizing: 'border-box',
                width: '20%',
                display: 'inline-block',
                // height: {
                //   xxs: 'auto',
                //   sm: theme.spacing(32.5),
                //   lg: theme.spacing(30.625),
                // },
                height: 'auto',
              }}
            >
              <Box
                margin={{
                  xxs: `${theme.spacing(3.375)} ${theme.spacing(2.5)}`,
                  sm: `${theme.spacing(1.25)} ${theme.spacing(1.875)}`,
                }}
                // textAlign={{xxs: 'center', sm: 'left', lg: 'center'}}
                onClick={() => {
                  handleTracking(eventTag, {
                    event_category: 'engagement',
                    Label: skill['title'],
                    Value: `${index}`,
                  });
                }}
                sx={{cursor: 'pointer'}}
              >
                <Link
                  href={skill['link']}
                  target="_blank"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Image
                    key={`home-skills-image-${index}`}
                    src={skill['imageUrl']}
                    alt={skill['title']}
                    width={isAboveMobile ? (isLargeXl ? 85 : 70) : 85}
                    height={isAboveMobile ? (isLargeXl ? 85 : 70) : 85}
                    placeholder="blur"
                    blurDataURL={skill['imageUrl']}
                    style={{
                      margin: isAboveMobile
                        ? isLargeXl
                          ? `${theme.spacing(1.875)} auto`
                          : `${theme.spacing(1.25)} auto`
                        : `${theme.spacing(1.25)} auto`,
                      alignContent: 'center',
                    }}
                  />
                  <Typography
                    component="p"
                    sx={{
                      fontSize: {
                        xxs: theme.typography.pxToRem(16),
                        sm: theme.typography.pxToRem(12),
                        md: theme.typography.pxToRem(13),
                      },
                      padding: 0,
                      opacity: 1,
                      lineHeight: 1.4,
                      cursor: 'pointer',
                      marginBottom: theme.spacing(1.25),
                      color: theme.palette.text.primary,
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {skill?.title?.split('|').length > 0 ? (
                      <>
                        {skill?.title?.split('|')[0]}
                        <br />
                        {skill?.title?.split('|')[1]}
                      </>
                    ) : (
                      skill?.title
                    )}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          ))
        ) : (
          <Box
            display={{xxs: 'block', md: 'flex'}}
            justifyContent={'center'}
            alignContent={'center'}
          >
            {[...new Array(7)].map((p, i) => (
              <Skeleton
                key={`home-enhanceskills-skeleton-${i}`}
                animation="wave"
                sx={{
                  width: {xxs: 100, md: 150},
                  height: {xxs: 80, md: 160},
                  margin: 2,
                }}
              />
            ))}
          </Box>
        )}
      </Grid>
    </Section>
  );
};
export default EnhanceSkill;
